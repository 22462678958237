.content {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  color: #636b6f;
}

.text_error {
  border-right: 2px solid;
  font-size: 26px;
  padding: 0 15px 0 15px;
  text-align: center;
}

.error_action {
  color: #636b6f;
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  padding: 0 15px 0 15px;
}
