.container
  width: 100%

  .main
    display: flex
    height: 100vh
    margin: 0 auto


  .main_left
    display: flex
    width: 50%
    background-color: #F6F8FB
    align-items: center
    justify-content: center


  .main_right
    display: flex
    width: 50%
    background-color: white
    align-items: center
    justify-content: center











