.app {
  width: 900px;
  box-shadow: 0px -2px 8px #E9ECF9, 0px 4px 8px #E9ECF9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 25px;

  h2 {
    margin: 0;
  }

  .label {
    margin: 0 0 5px 0;
    font-weight: 600;
    font-size: 14px;
  }
}

.error {
  height: 10px;
  margin: -6px 0 0 8px;
  color: red;
  width: 275px;
}

.wrap_witherror {
  display: flex;
  flex-direction: column;
}
