
.overlay {
  width: 100%;
  height: 100%;
  transition: 0.5s;
  background: rgba(106, 111, 116, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.modal {
  width: 500px;
  height: 200px;
  background-color: #F6F8FB;
  border-radius: 4px;
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h2 {
    margin: 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #bbb9b961;
    color: #333;
  }

  p {
    font-style: 18px;
  }

  small {
    font-size: 14px;
    color: #a3a1a1;
  }

  .actions {
    display: flex;
    margin-top: 35px;

    .delete {
      margin: 1px 0 0 15px;
    }
  }
}
