
.flight_card {
  width: 343px;
  height: 140px;
  border-radius: 8px;
  background: #006DF4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  min-width: 300px;
  margin: 0px 10px 15px 0px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  }

  .left_info,
  .right_info {
    margin: 14px;
    position: relative;
  }


  p {
    margin: 0;

    &.tests {
      font-size: 32px;
      margin-bottom: 15px;

      span {
        font-size: 20px;
      }
    }

    &.flight_number {
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 6px;
    }

    &.origin,
    &.destination {
      font-weight: 700;
      font-size: 18px;

      span {
        font-weight: 600;
        font-size: 11px;
        color: #ACCCF8;
      }
    }

    &.departure_time,
    &.arrival_time {
      font-weight: 600;
      font-size: 11px;
      color: #ACCCF8;
      letter-spacing: 1.5px;
    }

    &.timer {
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 6px;
    }

    &.counter {
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 6px;
    }
  }
}
