.flights_box {
  box-shadow: 0px -2px 8px #e9ecf9, 0px 4px 8px #e9ecf9;
  border-radius: 8px;
  padding: 40px;
  width: fit-content;
  margin-bottom: 20px;

  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #456A90;
    margin: 0;
    margin-bottom: 20px;
  }

  .flights_cards {
    display: flex;
  }
}

.info_single {
  display: flex;
}

.data_grid,
.single_view {
  h3 {
    margin: 0;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #456A90;
  }
}
