.filters {
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  font-size: 14px;

}

.filter > * {
  margin: 0 15px 10px 0 !important;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #DFDFDF;
  flex-wrap: wrap;


  &Button {
    display: flex;
    align-self: flex-end;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  &Text {
    padding: 0 5px;
  }

}

.rotate {
  transform: rotate(180deg);
}


.showFilter {
  display: flex;
  opacity: 1;
  height: auto;
  transition: all 0.3s linear;
}

.hideFilter {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.btn {
  font-size: 14px;
}




