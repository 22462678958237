.container
  width: 100%
  overflow: hidden

  .loaderWrapper
    height: 100vh
    position: absolute
    width: 100vw
    display: flex
    align-items: center
    justify-content: center
    background: #fcfcfda3


  .main
    display: flex
    height: 100vh
    margin: 0 auto


  .main_left
    display: flex
    width: 50%
    background-color: #F6F8FB
    align-items: center
    justify-content: center


  .main_right
    display: flex
    width: 50%
    background-color: white
    align-items: center
    justify-content: center











