
.submit {
  width: 250px;
  height: 50px;
  background-color: #006DF4;
  color: #F6F8FB;
  margin: 0 auto;
  display: block;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin: 60px;
}


.wrapper {
  height: 97vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.formWrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 80%;
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px;
  font-size: 16px;

  input {
    width: 100%;
    border: none;
    border-radius: 8px;
    box-shadow: 0px -2px 8px #E9ECF9, 0px 4px 8px #E9ECF9;
    padding: 12px;
    outline: 0;
    transition: 0.3s;

    &:hover {
      transform: scale(1.03);
      transition: 0.3s;
    }

  }

}


.error {
  border: 1px solid red;
}


.inputFeedback {
  color: rgb(235, 54, 54);
  font-size: 14px;
  margin-bottom: 20px;
}

