.content {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  color: #636b6f;
}

.text_error {

  font-size: 52px;
  padding: 0 15px 0 15px;
  text-align: center;
}

.error_action {
  color: #636b6f;
  font-size: 52px;
  text-align: center;
  padding: 0 15px 0 15px;
  text-decoration: underline;
}
