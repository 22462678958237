.wrapper {
  max-width: 1540px;
}

.grid_row {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #456A91;
  text-align: center;
  cursor: pointer;
}

.grid_row:nth-child(odd) {
  background-color: rgba(136, 160, 186, 0.06);
}

.header_row {
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 24px;
  color: #456A91;

  &:focus {
    outline: none;
  }
}
