.form_box {
  background: #fff;
  box-shadow: 0px -2px 8px #E9ECF9, 0px 4px 8px #E9ECF9;
  border-radius: 8px;
  padding: 40px;
  width: 900px;
}

.button {
  margin-left: 7px !important;
  margin-top: 10px !important;
}

.addButton {
  height: 30px;
  margin-left: 32px !important;
}

.checkBox {
  display: inline-block;
  margin: 6px 0 0 10px;
}

.section_input {
  margin-bottom: 15px;
  width: 100%;
}

.section_p {
  padding-left: 1rem;
  width: 100%;
}

.error {
  margin: -6px 0 0 8px;
  color: red;
}

.required {
  margin: -6px 0 0 8px;
  color: #c4c4c4;
}

.wrap_witherror {
  display: flex;
  flex-direction: column;
}

.wrap_section {
  display: flex;
  flex-wrap: wrap;
}


.error_server {
  margin-left: 8px;
  color: red;
}

.upload_logo_wrapper {
  width: 275px;
  margin: 8px;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  .upload_button {
    height: 56px;
  }

  p {
    margin: 0 0 0 5px;
    font-size: 14px;
  }
}
