.userView {
  display: flex;
}


.app {
  width: 575px;
  box-shadow: 0px -2px 8px #E9ECF9, 0px 4px 8px #E9ECF9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.row_style {
  background-color: #fff;

  &:nth-child(odd) {
    background: rgba(136, 160, 186, 0.06);
  }

  td:nth-child(1) {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #456A90;
    width: 200px;
  }

  td:nth-child(2) {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #456A90;
    padding: 0;
  }
}

td {
  border-bottom: none !important;

  img {
    width: 40px;
    vertical-align: middle;

    &.logo {
      width: 18px;
    }

    &.logo_air {
      width: 65px;
    }
  }
}

.button {
  padding: 20px;

  a {
    text-decoration: none !important;
  }

}

.user_card {
  background: #fff;
  box-shadow: 0px -2px 8px #e9ecf9, 0px 4px 8px #e9ecf9;
  border-radius: 8px;
  width: 666px;
  height: 326px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  .user_image {
    .initials {
      font-size: 24px;
    }
  }

  .user_info {
    margin-left: 20px;

    .user_name {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #456A90;
      margin: 0;
      margin-bottom: 5px;
    }

    .user_role {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #456A90;
      margin: 0;
    }
  }


}
