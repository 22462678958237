.info_card {
  min-width: 665px;
  height: 379px;
  background: #fff;
  box-shadow: 0px -2px 8px #E9ECF9, 0px 4px 8px #E9ECF9;
  border-radius: 8px;
  margin: 0 25px 25px 0;

  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #456A90;
    margin: 0;
    padding: 40px 0 50px 40px;
  }

  .metrics {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &.single {
      justify-content: flex-start;
      margin-left: 40px;
      margin-top: 15px;

      .metric {
        display: flex;
        align-items: center;

        p {
          margin-left: 35px;
        }
      }
    }

    h1 {
      font-weight: 400;
      font-size: 100px;
      line-height: 108px;
      color: #88A0BA;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #88A0BA;
      margin: 0;
    }
  }
}
