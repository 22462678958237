@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    font-family: 'Mulish', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDataGrid-filterFormOperatorInput {
    display: none !important;
}

.MuiDataGrid-columnHeader:focus {
    outline: none !important;
}

.MuiDataGrid-footerContainer {
    border: none !important;
}

.MuiDataGrid-cell:focus {
    outline: none !important;
}

.MuiTypography-root,
.MuiButtonBase-root,
.MuiDataGrid-root,
.MuiInputLabel-root,
.MuiInput-root,
.MuiTableCell-root,
.MuiInputBase-root,
.MuiFormLabel-root,
.MuiFormHelperText-root {
    font-family: 'Mulish', sans-serif !important;
}

.MuiFormHelperText-root {
    margin-left: 0 !important;
}

.MuiCheckbox-root {
    padding-left: 0 !important;
}
