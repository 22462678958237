.user_card {
  background: #fff;
  box-shadow: 0px -2px 8px #e9ecf9, 0px 4px 8px #e9ecf9;
  border-radius: 8px;
  width: 666px;
  height: 326px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  .user_image {
    .initials {
      font-size: 24px;
    }
  }

  .user_info {
    margin-left: 20px;

    .user_name {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #456A90;
      margin: 0;
      margin-bottom: 5px;
    }

    .user_role {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #456A90;
      margin: 0;
    }
  }


}
