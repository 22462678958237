.nestedList {
  display: flex;
  height: calc(100vh - 100px);


}

.content {
  width: 100%;
  padding: 40px 25px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0px 0px 5px 0px rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0px 0px 5px 0px rgba(34, 60, 80, 0.2) inset;
  overflow: auto;
}

.nestedList_width {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 340px;
  min-width: 340px;
  background-color: rgba(246, 248, 251, 1);
  overflow-y: hidden;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 150px;
  flex: 2;

  p {
    margin: 0;
  }
}
