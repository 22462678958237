.form_box {
  background: #fff;
  box-shadow: 0px -2px 8px #E9ECF9, 0px 4px 8px #E9ECF9;
  border-radius: 8px;
  padding: 30px;
  width: 900px;

  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #456A90;
    margin: 0;
    margin-bottom: 20px;
  }
}

.button {
  margin-left: 7px !important;
}

.section_input {
  margin-bottom: 15px;
  width: 100%;
}

.error {
  margin: -6px 0 0 8px;
  color: red;
  width: 275px;
}

.wrap_witherror {
  display: flex;
  flex-direction: column;
}

.wrap_section {
  display: flex;
  flex-wrap: wrap;
}

.checkBox {
  display: inline-block;
  margin: 6px 0 0 10px;
}
