.overlay {
    width: 100%;
    height: 100%;
    transition: 0.5s;
    background: rgba(106, 111, 116, 0.4);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.modal {
    width: 1000px;
    height: 500px;
    background-color: #F6F8FB;
    border-radius: 4px;
    padding: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;

    .errorRow {
        display: flex;
        border-bottom: 1px solid #dadada;
        justify-content: space-between;
    }

    p {
        font-size: 14px;
    }
}
  