
.modal {
  font-size: 12px;
  background-color: #F6F8FB;
  border-radius: 20px;
  padding: 20px;

  > {
    .header {
      width: 100%;
      border-bottom: 1px solid gray;
      font-size: 18px;
      text-align: center;
      padding: 5px;
    }


    .actions {
      width: 100%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;


    }

    .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      line-height: 20px;
      right: -10px;
      top: -10px;
      font-size: 24px;
      background: #ffffff;
      border-radius: 18px;
      border: 1px solid #cfcece;
    }


  }
}

.content {
  width: 100%;
  padding: 10px 5px;
  margin: 20px 0px;
}

a {
  cursor: pointer;
}

.button {
  display: flex;
  justify-content: center;
}
