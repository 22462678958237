.header {
  display: flex;
  border-bottom: 1px solid #E5E5E5;
  justify-content: space-between;
}

.header_logo {
  display: flex;
  align-items: center;
  background-color: #F6F8FB;
  width: 340px;
  height: 90px;
}

.logo {
  width: 340px;
}

.header_nav {
  display: flex;
  align-items: center;
}

.viewMode {
  display: flex;
  align-items: center;
  color: #006df4;
  font-weight: bold;
  font-size: 18px;
  padding: 0 10px;
  margin-right: auto;
}
