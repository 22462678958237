.form_box {
  background: #fff;
  box-shadow: 0px -2px 8px #e9ecf9, 0px 4px 8px #e9ecf9;
  border-radius: 8px;
  padding: 30px;
  width: 900px;
}

.button {
  margin-left: 7px !important;
}

.section_input {
  margin-bottom: 15px;
  width: 100%;
}

.checkBox {
  display: inline-block;
  margin: 6px 0 0 10px;
}

.section_p {
  padding-left: 1rem;
  width: 100%;
}

.error {
  margin: -6px 0 0 8px;
  color: red;
}

.required {
  margin: -6px 0 0 8px;
  color: #c4c4c4;
}

.wrap_witherror {
  display: flex;
  flex-direction: column;
}

.wrap_section {
  display: flex;
  flex-wrap: wrap;
}

.upload_logo_wrapper {
  width: 275px;
  margin: 8px;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  .upload_button {
    height: 56px;
  }

  p {
    margin: 0 0 0 5px;
    font-style: 14px;
  }
}

.modal {
  font-size: 12px;
  background-color: #f6f8fb;
  border-radius: 20px;
  padding: 20px;

  > {
    .header {
      width: 100%;
      border-bottom: 1px solid gray;
      font-size: 18px;
      text-align: center;
      padding: 5px;
    }

    .actions {
      width: 100%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;
    }

    .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      line-height: 20px;
      right: -10px;
      top: -10px;
      font-size: 24px;
      background: #ffffff;
      border-radius: 18px;
      border: 1px solid #cfcece;
    }
  }
}

.content {
  width: 100%;
  padding: 10px 5px;
  margin: 20px 0px;
}

.product {
  display: flex;
  align-items: center;
  gap: 4px;
}

a {
  cursor: pointer;
}

.button {
  display: flex;
  justify-content: center;
}
