.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #456A90;
}

.modalH1 {
  font-size: 40px;
  font-weight: bold;
}

.modalParagraph {
  font-size: 24px;
}
