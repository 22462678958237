.powered {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0px;

  &Header {
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  p {
    margin: 0;
    color: #636b6f;
    font-weight: bolder;

  }

  a {
    align-items: center;
    display: flex;
  }

  .logo {
    width: 100px;
  }
}


