
.item {

  a {
    display: block;
    width: 100%;
    text-decoration: none;
    color: black;
  }

}


.item .activeLink {
  background-color: #d9d9d9;

}

