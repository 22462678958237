.card {
  background: #fff;
  box-shadow: 0px -2px 8px #e9ecf9, 0px 4px 8px #e9ecf9;
  border-radius: 8px;
  width: 550px;
  justify-content: center;
  align-items: center;
  margin: 20px;
  flex-direction: column;
  z-index: 0;
}

.form_box {
  width: 100%;
}

.buttonWrapper {
  display: flex;
  align-items: self-end;
  margin-left: auto;
  align-self: center;
}

.button {
  margin: 10px;
  padding: 5px;
  width: 100px;
}

.productList {
  display: flex;
}

.product {
  display: flex;
  width: 100%;
  align-items: center;

}


