.select {
  //color: #006DF4!important;
  //font-weight: bold!important;

  fieldset {
    border: none !important;
  }

  svg {
    color: #006DF4 !important;
  }
}





