.wrapper {
  max-width: 1540px;
}

.link {
  color: #456A91;
  text-decoration: none;
  &:hover {
    text-decoration: underline!important;
  }
}



.colorRed {
  color: red;
}

.grid_row {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #456A91;
  text-align: center;

}

.grid_row:nth-child(odd) {
  background-color: rgba(136, 160, 186, 0.06);
}

.header_row {
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 24px;
  color: #456A91;

  &:focus {
    outline: none;
  }
}

.data_grid,
.single_view {
  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #456A90;
  }
}

.block_with_import {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
