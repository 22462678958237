
.modal {
  font-size: 12px;
  width: 500px;
  height: 300px;
  background-color: #F6F8FB;
  border-radius: 20px;
  padding: 120px;

  > {
    .header {
      width: 100%;
      border-bottom: 1px solid gray;
      font-size: 18px;
      text-align: center;
      padding: 5px;
    }

    .content {
      width: 100%;
      padding: 10px 5px;
    }

    .actions {
      width: 100%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;


    }

    .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      line-height: 20px;
      right: -10px;
      top: -10px;
      font-size: 24px;
      background: #ffffff;
      border-radius: 18px;
      border: 1px solid #cfcece;
    }


  }
}

a {
  cursor: pointer;
}

.button {
  width: 250px;
  height: 50px;
  background-color: #006DF4;
  color: #F6F8FB;
  margin: 0 auto;
  display: block;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
